'use client'
import React from "react"
import AdBanner from "../AdBanner"
import { X } from "lucide-react"

export const DefAd = () => {
  const [open, setOpen] = React.useState(true)

  return (
    open && (
      <div
        className='flex flex-col gap-2 items-end fixed bottom-20 right-6 bg-neutral rounded-lg p-2 z-50 2xl'>
        <div className='flex justify-between items-center w-full px-2'>
          <span>Reklama</span>
          <button className="btn btn-circle btn-sm" onClick={() => setOpen(!open)}>
            <X />
          </button>
        </div>
        <div className="w-80">
          <AdBanner
            data-ad-slot="5759306487"
            data-ad-format="auto"
            data-full-width-responsive="true" />
        </div>
      </div>
    )
  )
}
