'use client'
import React from 'react'
import { signOut } from 'next-auth/react'

export const SignOutBtn = () => {
  return (
    <a href="#"  className='rounded-lg' onClick={() => signOut({  redirect: true, callbackUrl: '/' })}>
      Wyloguj
    </a>
  )
}