'use client'
import React, { ReactComponentElement, ReactElement } from 'react'
import { PropsWithChildren, useEffect } from 'react'

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

interface AdBannerProps extends PropsWithChildren {
  maxWidth?: string;
  style?: React.CSSProperties;
}

const AdBanner = (props: AdBannerProps) => {
  const [error, setError] = React.useState(false)

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (err) {
      console.error(err)
      setError(true)
    }
  }, [])

  return (
    <div
      style={{ minWidth: '100%', margin:'auto', textAlign:'center' }}
      className='flex justify-center rounded-lg p-2 bg-base-100 xl'>
      <ins
        className="adsbygoogle adbanner-customize"
        style={{
          display: 'block',
          overflow: 'hidden',
          minWidth: '100%',
          ...(props.style as React.CSSProperties)
        }}
        data-ad-client={process.env.NEXT_PUBLIC_GOOGLE_ADS_CLIENT_ID}
        data-full-width-responsive="true"
        {...props}
      />
    </div>
  )
}
export default AdBanner